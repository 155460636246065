
import { BET_TYPE, BET_TYPES } from '@/types/global'
import { isIpadPro } from '@/utils'
import { PropType, computed, defineComponent, ref } from 'vue'

export default defineComponent({
    emits: ['place-bet'],
    props: {
        label: {
            type: String as PropType<string>,
            default: () => ''
        },
        color: {
            type: String as PropType<'red' | 'blue' | 'green' | ''>,
            default: () => ''
        },
        allowBet: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        betType: {
            type: String as PropType<BET_TYPES>,
            default: () => 'NA'
        },
        tempBet: {
            type: Number as PropType<number>,
            default: () => 0
        },
        confirmedBet: {
            type: Number as PropType<number>,
            default: () => 0
        },
        isWinner: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    },
    setup(props, { emit }) {
        const placeBet = () => {
            // if (props.allowBet)
            emit('place-bet', props.betType)
        }

        const totalbet = computed(() => props.tempBet + props.confirmedBet)

        const isIpadProDevice = computed((): boolean => isIpadPro())

        return {
            isIpadProDevice,
            placeBet,
            totalbet
        }
    }
})
