import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f918e12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flash"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bet-box-root", { 'is-not-ipad': !_ctx.isIpadProDevice }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.placeBet && _ctx.placeBet(...args)))
  }, [
    (_ctx.isWinner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["label-ratio", _ctx.color])
    }, _toDisplayString(_ctx.label), 3),
    (_ctx.totalbet > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["bet-amount", { 'has-temp-bet': _ctx.tempBet > 0 }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.totalbet), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}